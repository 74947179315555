import "./App.css";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./Home.js";
import FetchWiki from "./FetchWiki.js";
import Poricy from "./Poricy.js";
import ReactGA from "react-ga4";

function App() {
  ReactGA.initialize("G-S95E0BP0RS");
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />}></Route>
        <Route path="/fetchWiki" element={<FetchWiki />}></Route>
        <Route path="/poricy" element={<Poricy />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
