import React, { useEffect } from "react";

function PcUnderBanner(props) {
  useEffect(() => {
    const div = document.getElementById(props.divId);

    const script1 = document.createElement("script");
    script1.src = props.srcUrl;
    script1.async = true;
    div.appendChild(script1);

    const script2 = document.createElement("script");
    script2.textContent = props.inner;
    div.appendChild(script2);

    return () => {
      // コンポーネントがアンマウントされる際にスクリプトを削除
      div.removeChild(script1);
      div.removeChild(script2);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return <div id={props.divId} className="banner"></div>;
}
export default PcUnderBanner;
