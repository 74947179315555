import "./App.css";

function Poricy() {
  return (
    <div className="Poricy">
      <div className="Poricy-body">
        <h1>プライバシーポリシー</h1>
        <hr className="solid"></hr>
        　wikimiru（ウィキミル）（以下、「当サイト」といいます。）は、当サイト上で提供するサービス（以下、「本サービス」といいます。）における、ユーザーの個人情報の取扱いについて、以下のとおりプライバシーポリシー（以下、「本ポリシー」といいます。）を定めます。
        <h1>第1条（個人情報）</h1>
        <hr className="solid"></hr>
        　「個人情報」とは、個人情報保護法にいう「個人情報」を指すものとし、生存する個人に関する情報であって、当該情報に含まれる氏名、生年月日、住所、電話番号、連絡先その他の記述等により特定の個人を識別できる情報及び容貌、指紋、声紋にかかるデータ、及び健康保険証の保険者番号などの当該情報単体から特定の個人を識別できる情報（個人識別情報）を指します。
        <h1>第2条（個人情報の収集）</h1>
        <hr className="solid"></hr>
        　当サイトは、フォームよりお問い合わせいただく際にメールアドレス等の個人情報をお尋ねすることがあります。また、ユーザーと提携先などとの間でなされたユーザーの個人情報を含む取引記録や決済に関する情報を、当サイトの提携先（情報提供元、広告主、広告配信先などを含みます。以下、｢提携先｣といいます。）などから収集することがあります。
        <h1>第3条（当サイトの提携先に関する事項）</h1>
        <hr className="solid"></hr>
        <h3>1．アクセス解析ツールについて</h3>
        　当サイトでは、Googleによるアクセス解析ツール「Googleアナリティクス」を利用しています。このGoogleアナリティクスはトラフィックデータの収集のためにCookieを使用しています。このトラフィックデータは匿名で収集されており、個人を特定するものではありません。この機能はCookieを無効にすることで収集を拒否することが出来ますので、お使いのブラウザの設定をご確認ください。この規約に関して、詳しくは
        <a className="simpleLink" href="https://marketingplatform.google.com/about/analytics/terms/jp/" target="_blank" rel="noopener noreferrer">
          こちら
        </a>
        をクリックしてください。
        <h1>第4条（個人情報を収集・利用する目的）</h1>
        <hr className="solid"></hr>
        　当サイトが個人情報を収集・利用する目的は、以下のとおりです。
        <ol>
          <li>ユーザーからの問い合わせに対応するため</li>
          <li>不正・不当な目的でサービスを利用しようとするユーザーの特定をし、ご利用をお断りするため</li>
          <li>上記の利用目的に付随する目的</li>
        </ol>
        <h1>第5条（利用目的の変更）</h1>
        <hr className="solid"></hr>
        　当サイトは、利用目的が変更前と関連性を有すると合理的に認められる場合に限り、個人情報の利用目的を変更するものとします。 利用目的の変更を行った場合には、変更後の目的について、本ウェブサイト上に公表するものとします。
        <h1>第6条（個人情報の第三者提供）</h1>
        <hr className="solid"></hr>
        　当サイトは、次に掲げる場合を除いて、あらかじめユーザーの同意を得ることなく、第三者に個人情報を提供することはありません。ただし、個人情報保護法その他の法令で認められる場合を除きます。
        <ol>
          <li>人の生命、身体または財産の保護のために必要がある場合であって、本人の同意を得ることが困難であるとき</li>
          <li>公衆衛生の向上または児童の健全な育成の推進のために特に必要がある場合であって、本人の同意を得ることが困難であるとき</li>
          <li>国の機関もしくは地方公共団体またはその委託を受けた者が法令の定める事務を遂行することに対して協力する必要がある場合であって、本人の同意を得ることにより当該事務の遂行に支障を及ぼすおそれがあるとき</li>
          <li>予め次の事項を告知あるいは公表し、かつ当社が個人情報保護委員会に届出をしたとき</li>
          <ol>
            <li>利用目的に第三者への提供を含むこと</li>
            <li>第三者に提供されるデータの項目</li>
            <li>第三者への提供の手段または方法</li>
            <li>本人の求めに応じて個人情報の第三者への提供を停止すること</li>
            <li>本人の求めを受け付ける方法</li>
          </ol>
        </ol>
        前項の定めにかかわらず、次に掲げる場合には、当該情報の提供先は第三者に該当しないものとします。
        <ol>
          <li>当サイトが利用目的の達成に必要な範囲内において個人情報の取扱いの全部または一部を委託する場合</li>

          <li>
            個人情報を特定の者との間で共同して利用する場合であって、その旨並びに共同して利用される個人情報の項目、共同して利用する者の範囲、利用する者の利用目的および当該個人情報の管理について責任を有する者の氏名または名称について、あらかじめ本人に通知し、または本人が容易に知り得る状態に置いた場合
          </li>
        </ol>
        <h1>第7条（個人情報の開示）</h1>
        <hr className="solid"></hr>
        　ご本人からの保有個人データに関する開示のご請求があった場合には、これに応じます。
        <h1>第8条（個人情報の利用停止等）</h1>
        <hr className="solid"></hr>
        　当サイトは、本人から、個人情報が、利用目的の範囲を超えて取り扱われているという理由、または不正の手段により取得されたものであるという理由により、その利用の停止または消去（以下、「利用停止等」といいます。）を求められた場合には、遅滞なく必要な調査を行います。
        調査結果に基づき、その請求に応じる必要があると判断した場合には、遅滞なく、当該個人情報の利用停止等を行います。 当サイトは、前項の規定に基づき利用停止等を行った場合、または利用停止等を行わない旨の決定をしたときは、遅滞なく、これをユーザーに通知します。
        <h1>第9条（プライバシーポリシーの変更）</h1>
        <hr className="solid"></hr>
        　本ポリシーの内容は、法令その他本ポリシーに別段の定めのある事項を除いて、ユーザーに通知することなく、変更することができるものとします。 変更後のプライバシーポリシーは、本ウェブサイトに掲載したときから効力を生じるものとします。
        <h1>第10条（お問い合わせ）</h1>
        <hr className="solid"></hr>
        　本ポリシーに関するお問い合わせは、下記となります。
        <ul>
          <li>当サイト：wikimiru（ウィキミル）</li>
          <li>運営者：たつや、</li>
          <li>E-mail：t.uxottu@gmail.com</li>
          <li>twitter：https://twitter.com/Mw1FIDsnqdLbyDs</li>
        </ul>
        2024年4月6日 制定
        <footer className="App-footer">wikimiru © 2024</footer>
      </div>
    </div>
  );
}

export default Poricy;
