import React, { useState, useEffect } from "react";
import "./App.css";
import languageJson from "./language.json";
import PcUnderBanner from "./pcUnderBanner";

let pages = 0;

function FetchWiki() {
  const [title, setTitle] = useState("wikimiru");
  const [explanation, setExplanation] = useState("wikimiru（ウィキミル）とは、流れてくるwikipediaの情報を眺めるだけのサービスです。");
  const [url, setUrl] = useState("");
  const [timer, setTimer] = useState(5000);
  const [count, setCount] = useState(0);
  const [language, setLanguage] = useState("ja");
  const [showSelect, setShowSelect] = useState(false);
  const [width, setWidth] = useState(window.innerWidth);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const countUp = 100;
  const pcOrSp = 860;

  const fetchWikiData = async () => {
    await fetch(`https://${language}.wikipedia.org/w/api.php?action=query&format=json&generator=random&origin=*&prop=extracts|info&exintro&explaintext&indexpageids&grnnamespace=0&inprop=url`)
      .then((response) => response.json())
      .then((data) => {
        const pageId = data.query.pageids;
        setTitle(data.query.pages[pageId].title);
        setExplanation(data.query.pages[pageId].extract);
        setUrl(data.query.pages[pageId].fullurl);
        setTimer(setTimerUseWikiTextLength(data.query.pages[pageId].extract.length));
        pages++;
        if (pages % 10 === 0) {
          setIsModalOpen(true);
        }
      });
  };

  const setTimerUseWikiTextLength = (length) => {
    if (length < 100) return 10000;
    if (length < 200) return 15000;
    if (length < 300) return 20000;
    return 30000;
  };

  useEffect(() => {
    const interval = setInterval(async () => {
      await fetchWikiData();
      setCount(0);
    }, timer);

    return () => clearInterval(interval);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [timer, language]);

  useEffect(() => {
    const progress = setInterval(() => {
      setCount((prev) => prev + countUp);
    }, countUp);
    return () => clearInterval(progress);
  }, [timer]);

  // 広告用、幅次第でPC用かスマホ用か切り替える
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // hrefを設定してしまうと戻るときに2回戻らないといけなくなる
  const nextWiki = async () => {
    await fetchWikiData();
    setCount(0);
  };

  const handleChange = (value) => {
    setLanguage(value);
    setShowSelect(false);
  };

  const showSelectLanguage = () => {
    setShowSelect((prev) => !prev);
  };

  return (
    <div className="App">
      <div className="langHeader">
        <label className="selectbox-5">
          <img className="languageimage" alt="view" src={`${process.env.PUBLIC_URL}/image/public_FILL0_wght400_GRAD-25_opsz40.png`} onClick={showSelectLanguage}></img>
          {showSelect && (
            <select
              onChange={(e) => {
                handleChange(e.target.value);
              }}
              defaultValue={language}
            >
              {languageJson.map((json) => (
                <option key={json.value} value={json.value}>
                  {json.name}
                </option>
              ))}
            </select>
          )}
        </label>
      </div>
      <header className="App-header-haslang">
        {width >= pcOrSp && isModalOpen && (
          <div className="content">
            <div className="overlay">
              <div className="modal">
                <img
                  alt="view"
                  src={`${process.env.PUBLIC_URL}/image/cancel_FILL0_wght400_GRAD0_opsz24.png`}
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                ></img>
                <PcUnderBanner
                  divId="im-d48d6989046c4f1e8486cbf0ee27b6c6"
                  srcUrl="https://imp-adedge.i-mobile.co.jp/script/v1/spot.js?20220104"
                  inner='(window.adsbyimobile=window.adsbyimobile||[]).push({pid:82145,mid:571497,asid:1857408,type:"banner",display:"inline",elementid:"im-d48d6989046c4f1e8486cbf0ee27b6c6"})'
                ></PcUnderBanner>
              </div>
            </div>
          </div>
        )}
        {width < pcOrSp && isModalOpen && (
          <div className="content">
            <div className="overlay">
              <div className="modal">
                <img
                  alt="close"
                  src={`${process.env.PUBLIC_URL}/image/cancel_FILL0_wght400_GRAD0_opsz24.png`}
                  onClick={() => {
                    setIsModalOpen(false);
                  }}
                ></img>
                <PcUnderBanner
                  divId="im-10b5ab700159478f87867c0c99afda3a"
                  srcUrl="https://imp-adedge.i-mobile.co.jp/script/v1/spot.js?20220104"
                  inner='(window.adsbyimobile=window.adsbyimobile||[]).push({pid:82145,mid:571498,asid:1857435,type:"banner",display:"inline",elementid:"im-10b5ab700159478f87867c0c99afda3a"})'
                ></PcUnderBanner>
              </div>
            </div>
          </div>
        )}
        <div className="wiki">
          <p className="title">{title}</p>
          <p className="explanation">{explanation}</p>
        </div>
        {url && (
          <div className="flex">
            <div className="button_line004">
              <a className="url" href={url} target="_blank" rel="noopener noreferrer">
                詳しく
              </a>
            </div>
            <div className="button_line004">
              <a className="url" href="##" onClick={nextWiki}>
                次へ
              </a>
            </div>
          </div>
        )}
        <progress max={timer} value={count} className="progress"></progress>
        <a href="/" className="back">
          <img className="backimage" alt="view" src={`${process.env.PUBLIC_URL}/image/arrow_back_FILL0_wght400_GRAD0_opsz24.png`}></img>
        </a>
      </header>
      {width >= pcOrSp && (
        <PcUnderBanner
          divId="im-229bdd017d17455ab7b485ba2368024d"
          srcUrl="https://imp-adedge.i-mobile.co.jp/script/v1/spot.js?20220104"
          inner='(window.adsbyimobile = window.adsbyimobile || []).push({ pid: 82145, mid: 571497, asid: 1857261, type: "banner", display: "inline", elementid: "im-229bdd017d17455ab7b485ba2368024d" })'
        ></PcUnderBanner>
      )}
      {width < pcOrSp && (
        <PcUnderBanner
          divId="im-704bae5d7ff74d44a1cc235f23604d76"
          srcUrl="https://imp-adedge.i-mobile.co.jp/script/v1/spot.js?20220104"
          inner='(window.adsbyimobile=window.adsbyimobile||[]).push({pid:82145,mid:571498,asid:1857359,type:"banner",display:"inline",elementid:"im-704bae5d7ff74d44a1cc235f23604d76"})'
        ></PcUnderBanner>
      )}
      <footer className="App-footer">
        <span>ウィキペディアより引用</span>
        <span>クリエイティブ・コモンズ 表示-継承 4.0 国際ライセンスの下で利用可能です</span>
        wikimiru © 2024
      </footer>
    </div>
  );
}

export default FetchWiki;
