import React, { useState, useEffect } from "react";
import PcUnderBanner from "./pcUnderBanner";
import ReactGA from "react-ga4";
import "./App.css";

const pcOrSp = 860;

function Home() {
  const [width, setWidth] = useState(window.innerWidth);

  // 広告用、幅次第でPC用かスマホ用か切り替える
  useEffect(() => {
    const handleResize = () => setWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  ReactGA.send({ hitType: "pageview", page: "/fetchWiki" });
  return (
    <div className="App">
      <header className="App-header">
        <img className="image" alt="wikimiruLogo" src={`${process.env.PUBLIC_URL}/wikimiru.png`}></img>
        <div className="flex">
          <div className="button_line004">
            <a href="fetchWiki">
              <img className="image" alt="view" src={`${process.env.PUBLIC_URL}/image/outline_auto_stories_white_24dp.png`}></img>眺める
            </a>
          </div>
        </div>
      </header>
      {width >= pcOrSp && (
        <PcUnderBanner
          divId="im-229bdd017d17455ab7b485ba2368024d"
          srcUrl="https://imp-adedge.i-mobile.co.jp/script/v1/spot.js?20220104"
          inner='(window.adsbyimobile = window.adsbyimobile || []).push({ pid: 82145, mid: 571497, asid: 1857261, type: "banner", display: "inline", elementid: "im-229bdd017d17455ab7b485ba2368024d" })'
        ></PcUnderBanner>
      )}
      {width < pcOrSp && (
        <PcUnderBanner
          divId="im-704bae5d7ff74d44a1cc235f23604d76"
          srcUrl="https://imp-adedge.i-mobile.co.jp/script/v1/spot.js?20220104"
          inner='(window.adsbyimobile=window.adsbyimobile||[]).push({pid:82145,mid:571498,asid:1857359,type:"banner",display:"inline",elementid:"im-704bae5d7ff74d44a1cc235f23604d76"})'
        ></PcUnderBanner>
      )}
      <footer className="App-footer">
        <a className="simpleLink" href="poricy">
          プライバシーポリシー
        </a>
        <a className="simpleLink" href="https://forms.gle/PEFJ7uTjViTA3pzi6" target="_blank" rel="noopener noreferrer">
          お問い合わせ
        </a>
        wikimiru © 2024
      </footer>
    </div>
  );
}

export default Home;
